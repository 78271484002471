import React, { useState, useEffect, useContext, useCallback } from 'react';
import { navigate } from "@reach/router"
import { Space, Select } from 'antd';
import StateContext from '../providers/state';
import { withTranslation } from 'react-i18next';
import PageContainer from '../containers/PageContainer';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';


const Home = ({ storeId, category = '', t, i18n }) => {
  const [loadingQ, setLoadingQ] = useState(localStorage.getItem('storeId'));
  const [partner, setPartner] = useState(localStorage.getItem('partner'));
  const { setQuestions } = useContext(StateContext);
  const errorjson = require('../errorCodes.json')
  const { state, setImei, setImeiInfo, setCreateImei, setPromo, setOffer, setState } = useContext(StateContext);
  const [error, setError] = useState(false);
  if(!category){
    category = 'smartphone';
  }
  const [loading, setLoading] = useState();
  const { imei } = state;
  const [istormPromoEnabled, setIstormPromoEnabled] = useState(false);
  const [istormSecondPromoEnabled, setIstormSecondPromoEnabled] = useState(false);
  const [publicPromoEnabled, setPublicPromoEnabled] = useState(false);
  const errorText = t('home.error');
  const istormPromoDisabled = false;
  const istormSecondPromoDisabled = false;
  const publicPromoDisabled = false;
  sessionStorage.setItem('category', category);
  useEffect(() => {
    const storeId = localStorage.getItem('storeId');
    if (!storeId) {
      navigate('/login');
      return;
    }
    if (storeId.toLowerCase() === 'wind1') {
      navigate(`/voucher`);
      return;
    }
    // navigate(`/${storeId}`);
  }, []);

  useEffect(() => {
    if (loadingQ && !!localStorage.getItem('storeId')) {
      // fetch('http://server.greenpanda.io:3001/api/questions')
      fetch('https://server.greenpanda.io/scripts/getQuestions.php',)
        .then(response => response.json())
        .then(json => {
          setLoadingQ(false);
          console.log('questions', json)
          setQuestions(json);
        })
        .catch(error => {
          setLoadingQ(false);
        });
    }
  }, [loading, setQuestions]);
  // https://codesandbox.io/s/n5j7knjo54?file=/src/index.js:89-107

  const onImeiSend = useCallback(() => {
    if (!imei || typeof (imei) !== 'string') {
      return;
    }

    if(category !== 'smartwatch'){
      setLoading(true);
  
      let deviceType = (category != '') ? category : 'smartphone';
      state.deviceType = deviceType;
      fetch(`https://imei-service.greenpanda.io/offer`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imei: imei, deviceType: deviceType, currencies: 'EUR', partner: partner, istormPromo: istormPromoEnabled ? 'yes' : 'no', istormSecondPromo: istormSecondPromoEnabled ? 'yes' : 'no', pmmPromo: publicPromoEnabled ? 'yes' : 'no' })
      }).then(response => response.json())
        .then(deviceInfo => {
          if (deviceInfo.error) {
            setLoading(false);
            setError(errorjson[deviceInfo.error])
          } else {
            if((istormPromoEnabled || istormSecondPromoEnabled) && deviceInfo.price <= 50){
              setError('Η αξία της συσκευής πρέπει να ξεπερνάει τα 50€');
              setLoading(false);
              return;
            }
            setLoading(false);
            setState({...state, imeiInfo: deviceInfo, promo: {code: deviceInfo.promoCode, price: deviceInfo.promo}, offer: deviceInfo.price + deviceInfo.promo})
            navigate(`/${storeId}/offer`);
          }
        })
        .catch(e => {
          console.log('eskase', e)
          setLoading(false);
          setError(errorText);
        })
    }else{
      fetch(`https://imei-service.greenpanda.io/v2/device-info?imei=${imei}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then(response => response.json())
        .then(deviceInfo => {
          if (deviceInfo.error) {
            setLoading(false);
            setError(errorjson[deviceInfo.error])
          } else {
            setLoading(false);
            setImeiInfo(deviceInfo);
  
            console.log("Go to Offer")
            console.log(deviceInfo)
            
            navigate(`/${storeId}/conditions`);
          }
        })
        .catch(e => {
          console.log('eskase', e)
          setLoading(false);
          setError(errorText);
        })
    }
    
  }, [imei, storeId, istormPromoEnabled, publicPromoEnabled, istormSecondPromoEnabled]);

  const onImeiCreate = (brand) => {
    setCreateImei(true, brand);
    navigate(`/${storeId}/offer`);
  }

  const onChangeInput = event => {
    const input = event.target.value;
    setImei(input);
    setError(false)
  };

  if (loadingQ) {
    return (
      <div id="overlayCenter">
        <Space size="large">
          <Spinner animation="border" className="mr-3" />
        </Space>
      </div>
    );
  }

  return (
    <PageContainer progress="100" noExit voucher>
      <div style={{ width: '70%', textAlign: 'center' }}>
        {(partner.includes("istorm") || partner.includes("greenpanda")) &&
          <ul className="nav nav-tabs" style={{ margin: '0 0 40px 0' }}>
            <li className="nav-item" onClick={() => {sessionStorage.setItem('category', 'smartphone')}}>
            {console.log(category)}
              {category !== 'smartphone' ?
              
                <a href="/" className="nav-link" style={{ color: 'black' }}>iPhones</a>
                :
                <a href="/" className="nav-link unclickable active" style={{ color: '#41d900', fontWeight: '500' }}>iPhones</a>
              }
            </li>
            <li className="nav-item" onClick={() => {sessionStorage.setItem('category', 'ipad')}}>
              {category !== 'ipad' ?
                <a href={"/" + storeId + "/ipad"} className="nav-link" style={{ color: 'black' }}>iPads</a>
                :
                <a href={"/" + storeId + "/ipad"} className="nav-link unclickable active" style={{ color: '#41d900', fontWeight: '500' }}>iPads</a>
              }
            </li>
            {(partner.includes("istorm")) && process.env.REACT_APP_SMARTWATCH_ENABLED.toLowerCase() === 'true' &&
            <li className="nav-item" onClick={() => {sessionStorage.setItem('category', 'smartwatch')}}>
              {category !== 'smartwatch' ?
                <a href={'/' + storeId + "/smartwatch"} className="nav-link" style={{ color: 'black' }}>Watches</a>
                :
                <a href={"/" + storeId + "/smartwatch"} className="nav-link unclickable active" style={{ color: '#41d900', fontWeight: '500' }}>Watches</a>
              }
            </li>}
            
          </ul>
        }


        <Form onSubmit={(e) => { e.preventDefault(); }}>

          <h1 className="title text-center mt-3" onClick={() => { navigator.clipboard.writeText(357296092404683) }}>{t('home.title')}</h1>
          {!category && <h4 className="mb-4">{t('home.description')}</h4>}
          {category && <h4 className="mb-4">{t('home.descriptionIpad')}</h4>}

          <Form.Group controlId="formBasicCheckbox">
            <Form.Control
              type="text"
              name="imei"
              pattern="^[a-zA-Z0-9_.-]*$"
              value={imei || ""}
              disabled={loading}
              onChange={onChangeInput}
              maxLength="15"
              size="large"
              style={{ fontSize: "25px", textAlign: "center" }}
            />
          </Form.Group>
          {!!error && <Alert variant={'danger'}> {error} </Alert>}
          <Button
            className="primaryButton button"
            size="small"
            type="submit"
            disabled={loading || !imei}
            onClick={onImeiSend}
          >
            {loading && <Spinner animation="border" className="mr-3" />}
            <span>{t('home.cta')}
            </span></Button>
            {!istormPromoDisabled && partner.includes("istorm") && category === 'smartphone' && <div className='d-flex align-items-center text-left mt-5 mx-auto' style={{maxWidth: '560px'}}>
              <input onChange={(e) => {setIstormPromoEnabled(e.target.checked)}} style={{height: '50px', width: '50px'}} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
              <label className='m-0 ml-2' for="vehicle1"> Πάρε <b>100€</b> επιπλέον για την αγορά του <b>iPhone 15 series</b>. Προϋπόθεση είναι η αξία της trade-in συσκευής να είναι πάνω από 50€.</label>
            </div>}
            {console.log(partner)}
            {!istormSecondPromoDisabled && partner.includes("istorm") && category === 'smartphone' && <div className='d-flex align-items-center text-left mt-5 mx-auto' style={{maxWidth: '560px'}}>
              <input onChange={(e) => {setIstormSecondPromoEnabled(e.target.checked)}} style={{height: '50px', width: '50px'}} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
              <label className='m-0 ml-2' for="vehicle1"> Πάρε <b>50€</b> επιπλέον για την αγορά του <b>iPhone 13, 14 & 14 Plus</b>. Προϋπόθεση είναι η αξία της trade-in συσκευής να είναι πάνω από 50€.</label>
            </div>}
            {!publicPromoDisabled && (partner.includes("pmmgr") || partner.includes('pmmcy') || partner.includes("mediamarktgr")) && category === 'smartphone' && <div className='d-flex align-items-center text-left mt-5 mx-auto' style={{maxWidth: '560px'}}>
              <input onChange={(e) => {setPublicPromoEnabled(e.target.checked); console.log(publicPromoEnabled)}} style={{height: '50px', width: '50px'}} type="checkbox" id="publicPromo" name="publicPromo" value="" />
              <label className='m-0 ml-2' for="publicPromo"> Πάρε <b>50€</b> επιπλέον. Προϋπόθεση είναι το μοντέλο της trade-in συσκευής να είναι νεότερη του iPhone 11</label>
            </div>}
            {(storeId.toLowerCase().includes('scooter') || storeId.toLowerCase().includes('courier')) && <Button
              className="samsungButton button"
              size="small"
              type="submit"
              disabled={loading || imei}
              onClick={()=>onImeiCreate('samsung')}
            >
              <span>{t('home.samsungImeiCreate')}</span>
            </Button>}
            {(storeId.toLowerCase().includes('scooter') || storeId.toLowerCase().includes('courier')) && <Button
              className="xiaomiButton button"
              size="small"
              type="submit"
              disabled={loading || imei}
              onClick={()=> {onImeiCreate('xiaomi')}}
            >
              <span>{t('home.xiaomiImeiCreate')}</span>
            </Button>}
        </Form>
      </div>
    </PageContainer>
  );
}

export default withTranslation()(Home);
